.font-secondary {
  font-family: var(--font-secondary);
}

.h-color--white {
  color: var(--white);
}

.h-color--blue {
  color: var(--blue);
}

.h-color--navy-200 {
  color: var(--navy-200);
}

.h-color--black {
  color: var(--black) !important;
}

/* .h-container--small {
    margin: 0 auto;
    max-width: 900px;
    width: 85%;
} */

.h-container {
  max-width: 1200px;
  margin: 0 auto;
  width: 90%;
}

.h-container--small {
  max-width: 800px;
  margin: 0 auto;
  width: 90%;
}

.h-container--large {
  max-width: 2000px;
  margin: 0 auto;
  width: 90%;
}

.l-flex {
  display: flex;
}

.l-flex--column {
  display: flex;
  flex-direction: column;
}

.l-flex-one {
  flex: 1;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.m-b--none {
  margin-bottom: 0;
}

.m-b--xs {
  margin-bottom: 0.2rem;
}

.m-b--small {
  margin-bottom: 0.6rem;
}

.m-b {
  margin-bottom: 1rem;
}

.m-b--med {
  margin-bottom: 1.5rem;
}

.m-b--large {
  margin-bottom: 2rem;
}

.m-b--xl {
  margin-bottom: 2rem;
}

.m-x {
  margin-left: 1rem;
  margin-right: 1rem;
}

.m-x--large {
  margin-left: 2rem;
  margin-right: 2rem;
}

.m-t {
  margin-top: 1rem;
}

.m-t--med {
  margin-top: 1.5rem;
}

.m-t--large {
  margin-top: 2rem;
}

.m-t--xl {
  margin-top: 3rem;
}

.m-t--2xl {
  margin-top: 4rem;
}

.m-t--auto {
  margin-top: auto;
}

.m-y {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.m-l--auto {
  margin-left: auto;
}

.m-l {
  margin-left: 1rem;
}

.m-l--small {
  margin-left: 0.6rem;
}

.m-r {
  margin-right: 1rem;
}

.m-r--xxsmall {
  margin-right: 0.2rem;
}

.m-r--small {
  margin-right: 0.6rem;
}

.m-r--med {
  margin-right: 1.5rem;
}

.p-y {
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.p-y--large {
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.p-top--none:first-child {
  padding-top: 0 !important;
}

.p {
  padding: 1rem;
}

.p-t--large {
  padding: 2rem !important;
}

.p-large {
  padding: 2rem;
}

.p-xl {
  padding: 3rem;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.h-fadein {
  animation: fadeIn 800ms ease 0s;
}

.h-image-cover {
  object-fit: cover;
  height: 100%;
  position: absolute;
  width: 100%;
}

.h-display--block {
  display: block;
}

.weight-400 {
  font-weight: 400;
}

.weight-600 {
  font-weight: 600;
}

.weight-700 {
  font-weight: 700;
}

.h-font--special {
  font-family: var(--font-special);
}

.h-font--primary {
  font-family: 'raleway', sans-serif;
}

.h-font--uppercase {
  text-transform: uppercase;
}

.h-font--capitalize {
  text-transform: capitalize;
}

.h-rotate {
  transform: rotate(180deg);
}

.h-section {
  padding: 3rem 0;
}

.w-100 {
  width: 100%;
}

.h-background--blue {
  background-color: var(--blue);
}

.h-background--navy-200 {
  background-color: var(--navy-200);
}

.h-background--purple-200 {
  background-color: var(--purple-200);
}

.h-background--white {
  background-color: var(--white);
}

.h-underline--blue {
  display: inline-block;
  position: relative;
}

.h-underline--blue:after {
  background-color: var(--blue);
  bottom: -1rem;
  content: '';
  height: 4px;
  left: 0;
  position: absolute;
  width: 40%;
}

.h-events--none {
  pointer-events: none;
}

.desktop-only {
  display: none;
}

.mobile-only {
  display: block;
}

.rotate-90 {
  transform: rotate(90deg);
}

.m-w-4 {
  max-width: 1rem;
}

@media screen and (min-width: 63em) {
  .mobile-only {
    display: none;
  }

  .desktop-only {
    display: block;
  }
}

.c-card {
  background-color: var(--white);
  border-radius: 4px;
  display: inline-block;
  padding: 2.5rem 3rem;
  width: 100%;
}

.c-card__inner {
  align-items: flex-start;
  color: var(--black);
  display: flex;
  flex-direction: column-reverse;
}

.c-card__image {
  margin-bottom: 1.5rem;
  margin-left: 0;
}

.c-card__content {
  flex: 1;
}

@media (min-width: 63em) {
  .c-card__inner {
    align-items: center;
    flex-direction: row;
  }

  .c-card__image {
    margin-left: 1.5rem;
  }
}

.ek-theme-breakoutbox-light-blue-page h1 {
  color: #000;
}
